window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-obfuscated-link').forEach((link) => {
    link.addEventListener('click', () => {
      const path = window.atob(link.getAttribute('data-o'));
      const target = link.getAttribute('target');
      const noOpener = link.getAttribute('rel') && link.getAttribute('rel') === 'noopener';
      const myWindow = window.open(path, target || '_self');

      if (noOpener) myWindow.opener = null;
    });
  });

  // This listener adds URL query parameters (e.g. `a=1&b=2`) that are defined in a dedicated data
  // attribute of the element to the URL. The base URL is supposed to **not already contain query
  // parameters itself**. The goal is to avoid query params for SEO purposes.
  document.querySelectorAll('a[data-href-params]').forEach((anchor) => {
    anchor.addEventListener('click', (event) => {
      const path = `${anchor.getAttribute('href')}?${anchor.getAttribute('data-href-params')}`;
      event.preventDefault();
      window.location.href = path;
    });
  });
});
