import Pikaday from 'pikaday';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import dateFormat from '../../../frontend/javascripts/utils/date_formats';

dayjs.extend(dayjsCustomParseFormat);

const pikadayI18n = {
  en: {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    weekdays: [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
    ],
    weekdaysShort: [
      'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
    ],
  },
  fr: {
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    weekdays: [
      'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi',
    ],
    weekdaysShort: [
      'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam',
    ],
  },
};

const formatDate = (date, format = dateFormat) => (date ? dayjs(date).format(format) : null);

const parseDate = (dateString, format = dateFormat) => (
  dateString ? dayjs(dateString, format).toDate() : null
);

window.addEventListener('DOMContentLoaded', () => {
  const datePickerField = document.querySelector('.js-form-date-picker');
  const datePickerLabel = document.querySelector('.js-form-date-picker + label');
  const datePickerErrorMessage = document.querySelector('.js-form-date-picker + label + .form-input-message--error');

  if (datePickerField) {
    const currentPikadayI18n = pikadayI18n[document.documentElement.lang] || pikadayI18n.en;
    const {
      minDate, maxDate, selectedDate, defaultDate,
    } = datePickerField.dataset;

    const pika = new Pikaday({
      field: datePickerField,
      format: dateFormat[document.documentElement.lang] || dateFormat.en,
      yearRange: 200,
      defaultDate: parseDate(defaultDate),
      i18n: {
        previousMonth: datePickerField.dataset.prev,
        nextMonth: datePickerField.dataset.next,
        months: currentPikadayI18n.months,
        weekdays: currentPikadayI18n.weekdays,
        weekdaysShort: currentPikadayI18n.weekdaysShort,
      },
      toString: formatDate,
      parse: parseDate,
      onSelect: (date) => {
        if (!date || (parseDate(selectedDate) - date) === 0) return;

        datePickerField.classList.remove('form-date-picker__field--error');
        datePickerLabel.classList.remove('form-date-picker__label--error');
        if (datePickerErrorMessage) datePickerErrorMessage.remove();
      },
    });

    if (minDate) {
      pika.setMinDate(parseDate(minDate));
    }

    if (maxDate) {
      pika.setMaxDate(parseDate(maxDate));
    }
    if (selectedDate) {
      pika.setDate(parseDate(selectedDate));
    }
  }
});
