window.addEventListener('DOMContentLoaded', () => {
  const formSelectFields = document.querySelectorAll('.js-form-select');

  formSelectFields.forEach((formSelectField) => {
    const setFormSelectLabelClass = () => {
      formSelectField.querySelectorAll('option').forEach((formSelectFieldOption) => {
        if (formSelectFieldOption.selected) {
          formSelectField.classList.toggle('form-select__field--with-placeholder', formSelectFieldOption.value);
        }
      });
    };
    formSelectField.addEventListener('change', setFormSelectLabelClass);
    setFormSelectLabelClass();
  });
});
