window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-show-more').forEach((button) => {
    const itemsCssClass = button.getAttribute('data-items-class');
    const itemQuery = `.js-${itemsCssClass}`;
    const items = document.querySelectorAll(itemQuery);

    if (button && items.length) {
      const hiddenItemsCssClass = `${itemsCssClass}--hidden`;
      let numItemsShown = document.querySelectorAll(`${itemQuery}:not(.${hiddenItemsCssClass})`).length;
      const numItemsToShow = parseInt(button.getAttribute('data-number-items-to-show'), 10) || Infinity;
      const numMaxItems = items.length;

      button.addEventListener('click', (event) => {
        event.preventDefault();

        [...items]
          .slice(numItemsShown, numItemsShown + numItemsToShow)
          .forEach((item) => item.classList.remove(hiddenItemsCssClass));
        numItemsShown += numItemsToShow;

        if (numItemsShown >= numMaxItems) button.remove();
      });
    }
  });
});
