import { toRem } from '../../../frontend/javascripts/utils/styles';

const BUTTON_GROUP_PADDING = 5;

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-form-button-group').forEach((formButtonGroup) => {
    const options = formButtonGroup.querySelectorAll('.js-form-button-group-option');
    const optionsCount = options.length;
    const optionsWidth = 100 / optionsCount;
    const optionBackground = formButtonGroup.querySelector('.js-form-button-group-option-background');

    const setFormButtonGroupOptionCheckedClass = () => {
      options.forEach((option) => {
        if (option.checked) {
          const optionIndex = Array.from(options).indexOf(option);
          const optionsWidthCalculated = optionsWidth * (optionIndex);
          optionBackground.classList.add('form-button-group__option-background--active');
          optionBackground.style.width = `calc((100% - ${toRem(BUTTON_GROUP_PADDING * 2)}) / ${optionsCount})`;
          optionBackground.style.left = optionIndex === 0 ? toRem(BUTTON_GROUP_PADDING) : `${optionsWidthCalculated}%`;
        }
      });
    };

    formButtonGroup.addEventListener('change', setFormButtonGroupOptionCheckedClass);
    setFormButtonGroupOptionCheckedClass();
  });
});
