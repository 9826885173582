window.addEventListener('DOMContentLoaded', () => {
  const cardModifierCssClass = 'pro-accordion-card--collapsed';
  const cardHeaders = document.querySelectorAll('.js-pro-accordion-card-header');
  cardHeaders.forEach((cardHeader) => {
    cardHeader.addEventListener('click', () => {
      const card = cardHeader.parentElement;
      if (card.classList.contains(cardModifierCssClass)) {
        card.classList.remove(cardModifierCssClass);
      } else {
        card.classList.add(cardModifierCssClass);
      }
    });
  });
});
