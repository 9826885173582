window.addEventListener('DOMContentLoaded', () => {
  // We render description component twice, once for mobile, once for desktop.
  // Because of this we need to attach handlers to all buttons.
  const buttons = document.querySelectorAll('.js-pro-description-show-more-text');
  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      // Because we don't know which button was clicked
      // we need to remove css class from all of the hidden divs.
      const elements = document.querySelectorAll('.js-pro-description-text-to-show');
      elements.forEach((element) => {
        element.classList.remove('pro-description--hidden');
      });
      // Users with big phones can first trigger "s" breakpoint in vertical,
      // and then tilt the phone horizontally and trigger "m" or "l" breakpoint.
      // So we remove all buttons when currently visible one was clicked.
      buttons.forEach((b) => b.remove());
    });
  });
});
