document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('.js-slider');

  if (sliders.length) {
    sliders.forEach((slider) => {
      const arrowLeft = slider.querySelector('.js-slider-arrow-left');
      const arrowRight = slider.querySelector('.js-slider-arrow-right');
      const sliderContent = slider.querySelector('.js-slider-content');

      let scrollerTimer = null;
      const numCards = sliderContent.querySelectorAll('.js-slider-card').length;
      const maxNumVisibleCards = 3;
      const cardDesktopWidth = 363; // pixels
      const cardDesktopPadding = 25; // pixels
      const cardDesktopWidthAndPadding = cardDesktopWidth + cardDesktopPadding;
      const sliderContentScrollableWidth = Math.max(
        (cardDesktopWidthAndPadding * (numCards - maxNumVisibleCards)) - cardDesktopPadding,
        0,
      );

      const displayOrHideArrowLeft = () => {
        if (sliderContent.scrollLeft === 0) {
          arrowLeft.classList.add('slider__arrow--hidden');
        } else {
          arrowLeft.classList.remove('slider__arrow--hidden');
        }
      };

      const displayOrHideArrowRight = () => {
        if (sliderContentScrollableWidth === 0) {
          arrowRight.classList.add('slider__arrow--hidden');
        } else if (sliderContent.scrollLeft >= sliderContentScrollableWidth) {
          arrowRight.classList.add('slider__arrow--hidden');
        } else {
          arrowRight.classList.remove('slider__arrow--hidden');
        }
      };

      if (sliderContent && arrowLeft) {
        arrowLeft.addEventListener('click', (event) => {
          event.preventDefault();
          sliderContent.scrollBy({
            top: 0,
            left: -cardDesktopWidthAndPadding,
            behavior: 'smooth',
          });
        });
      }

      if (sliderContent && arrowRight) {
        arrowRight.addEventListener('click', (event) => {
          event.preventDefault();
          sliderContent.scrollBy({
            top: 0,
            left: cardDesktopWidthAndPadding,
            behavior: 'smooth',
          });
        });
      }

      if (sliderContent) {
        sliderContent.addEventListener('scroll', () => {
          if (scrollerTimer !== null) clearTimeout(scrollerTimer);
          scrollerTimer = setTimeout(() => {
            if (arrowLeft) displayOrHideArrowLeft();
            if (arrowRight) displayOrHideArrowRight();
          }, 500);
        }, false);

        if (arrowLeft) displayOrHideArrowLeft();
        if (arrowRight) displayOrHideArrowRight();
      }
    });
  }
});
