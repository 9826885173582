window.addEventListener('DOMContentLoaded', () => {
  const readMoreElements = document.querySelectorAll('.js-read-more');
  if (readMoreElements.length > 0) {
    readMoreElements.forEach((readMore) => {
      const contentContainer = readMore.querySelector('.js-read-more-content');
      const text = contentContainer.textContent;
      const maxCharacters = contentContainer.getAttribute('data-max-characters');
      if (text.length > maxCharacters) {
        const readMoreText = contentContainer.getAttribute('data-read-more');
        // Reset texts and append "Lire la suite" link
        contentContainer.textContent = '';
        const reviewContent = document.createElement('span');
        reviewContent.textContent = `${text.substring(0, maxCharacters - 3)}... `;
        const readMoreLink = document.createElement('a');
        readMoreLink.classList.add('link', 'read-more__link');
        readMoreLink.textContent = readMoreText;
        contentContainer.appendChild(reviewContent);
        contentContainer.appendChild(readMoreLink);
        readMoreLink.addEventListener('click', () => {
          contentContainer.textContent = text;
        });
      }
    });
  }
});
