function toggleRailsHeaderInMobile(hideOrShow) {
  const header = document.querySelector('.js-main-header');
  if (!header) return;
  if (hideOrShow === 'hide') {
    header.classList.add('header--hide-in-mobile');
  } else {
    header.classList.remove('header--hide-in-mobile');
  }
}

window.hideRailsHeaderInMobile = () => toggleRailsHeaderInMobile('hide');
window.showRailsHeaderInMobile = () => toggleRailsHeaderInMobile('show');
