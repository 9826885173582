document.addEventListener('DOMContentLoaded', () => {
  const arrow = document.querySelector('.js-country-icon');
  if (arrow) {
    const toggleDropdown = (arrowElement, dropdown) => {
      dropdown.classList.toggle('footer__country-dropdown--hidden');
      arrowElement.classList.toggle('footer__country-icon--up');
    };

    const dropdown = document.querySelector('.js-country-dropdown');
    arrow.addEventListener('click', (event) => {
      event.preventDefault();
      toggleDropdown(arrow, dropdown);
    });

    // click outside the dropdown handler
    document.addEventListener('click', (event) => {
      const isClickInside = dropdown.contains(event.target);
      const isDropdownOpen = dropdown.classList.contains('footer__country-dropdown')
        && !dropdown.classList.contains('footer__country-dropdown--hidden');
      const isArrowClicked = arrow.contains(event.target);

      if (!isClickInside && isDropdownOpen && !isArrowClicked) {
        toggleDropdown(arrow, dropdown);
      }
    });
  }
});
