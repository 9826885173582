window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-pro-presentation').forEach((container) => {
    const button = container.querySelector('.js-pro-presentation-show-more-text');
    if (button) {
      button.addEventListener('click', () => {
        const element = container.querySelector('.js-pro-presentation-text-to-show');
        element.classList.remove('pro-presentation--hidden');
        button.remove();
      });
    }
  });
});
