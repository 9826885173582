import MmenuLight from 'mmenu-light';

document.addEventListener('DOMContentLoaded', () => {
  const menuElement = document.querySelector('.js-menu');

  if (menuElement) {
    const menu = new MmenuLight(menuElement, '(max-width: 1093px)');
    const drawer = menu.offcanvas();
    const navigator = menu.navigation();

    document.querySelector('.js-menu-open').addEventListener('click', (event) => {
      event.preventDefault();
      drawer.open();
      navigator.openPanel(document.querySelector('.js-menu-main-panel'));
    });
  }
});
