window.addEventListener('DOMContentLoaded', () => {
  const carousel = document.querySelector('.js-carousel');
  if (carousel) {
    const arrowLeft = carousel.querySelector('.js-carousel-arrow-left');
    const arrowRight = carousel.querySelector('.js-carousel-arrow-right');
    const activeClassNames = [
      'carousel__description--active',
      'carousel__screen--active',
      'carousel__dot--active',
    ];

    const slideTo = (way) => {
      activeClassNames.forEach((className) => {
        const activeElement = carousel.querySelector(`.${className}`);
        activeElement.classList.remove(className);
        const sibling = way === 'right' ? activeElement.nextElementSibling : activeElement.previousElementSibling;
        sibling.classList.add(className);
      });
    };

    const handleArrows = (way) => {
      const oppositeArrow = way === 'right' ? arrowLeft : arrowRight;
      const arrow = way === 'right' ? arrowRight : arrowLeft;
      const activeScreen = carousel.querySelector('.js-carousel-screen.carousel__screen--active');
      const siblingElement = way === 'right' ? activeScreen.nextElementSibling : activeScreen.previousElementSibling;
      oppositeArrow.disabled = false;
      arrow.disabled = !siblingElement.matches('.js-carousel-screen');
    };

    const handleSlideTo = (way) => {
      slideTo(way);
      handleArrows(way);
    };

    arrowRight.addEventListener('click', () => handleSlideTo('right'));
    arrowLeft.addEventListener('click', () => handleSlideTo('left'));
  }
});
