window.addEventListener('DOMContentLoaded', () => {
  const navigation = document.querySelector('.js-tabs-with-anchor');

  if (navigation) {
    // The Wecasa main site Wecasa header
    const header = document.querySelector('.js-main-header');
    // Set sticky top for navigation
    const stickyTop = navigation.dataset.stickytop;
    navigation.style.top = `${stickyTop}px`;
    // The main container for the page scrolling
    const scrollContainer = document.body;

    // All sections to which we can navigate to
    const scrollSpySectionIds = [...document.querySelectorAll('.js-tabs-with-anchor-section')].map(
      (section) => (section.id),
    );

    // The first section is active by default
    const firstSectionId = scrollSpySectionIds[0];
    let currentlyActiveSectionId = firstSectionId;

    const getFullHeaderOffset = () => (
      header
        ? header.offsetHeight + navigation.offsetHeight
        : navigation.offsetHeight
    );

    const setSectionActive = (sectionId) => {
      currentlyActiveSectionId = sectionId;

      let activeTab = null;

      // Update active classes on items, anchors and potential svg icons
      document.querySelectorAll('.js-tabs-with-anchor-anchor').forEach((navigationLink) => {
        const navigationItem = navigationLink.parentElement;
        const svgIcon = navigationLink.querySelector('svg');

        navigationItem.classList.remove('tabs-with-anchor__item--active');
        navigationLink.classList.remove('tabs-with-anchor__anchor--active');
        if (svgIcon) svgIcon.classList.remove('tabs-with-anchor__icon--active');

        if (navigationLink.getAttribute('href') === `#${currentlyActiveSectionId}`) {
          activeTab = navigationLink;
          navigationItem.classList.add('tabs-with-anchor__item--active');
          navigationLink.classList.add('tabs-with-anchor__anchor--active');
          if (svgIcon) svgIcon.classList.add('tabs-with-anchor__icon--active');
        }
      });

      // If there are a lot of tabs on mobile, we need to scroll horizontally to the active tab
      // inside the navigation so that the active tab is never hidden.
      if (!navigation.dataset.disableHorizontalScroll && activeTab) {
        const isFirstTab = activeTab.getAttribute('href') === `#${firstSectionId}`;
        const navigationList = document.querySelector('.js-tabs-with-anchor-list');
        const permanentOffset = navigation.dataset.permanentOffset || 0;

        navigationList.scrollTo({
          top: 0,
          left: isFirstTab ? 0 : activeTab.parentElement.offsetLeft - permanentOffset,
        });
      }
    };

    /**
     * Scroll spy behaviour to update the active state of the navigation tabs
     * depending on the scroll position.
     */
    const updateNavigationTabsOnScroll = () => {
      const scrollOffset = scrollContainer.scrollTop;

      let sectionThatShouldBeActiveId = null;

      document.querySelectorAll('.js-tabs-with-anchor-section').forEach((section) => {
        if (scrollOffset >= (section.offsetTop - getFullHeaderOffset())) {
          sectionThatShouldBeActiveId = section.id;
        }
      });

      // The first tab should always be active even if its section is not yet in the scrolled zone
      if (!sectionThatShouldBeActiveId) {
        sectionThatShouldBeActiveId = firstSectionId;
      } else if ((window.innerHeight + scrollOffset) >= (scrollContainer.scrollHeight - 50)) {
        // If the scroll is on the page bottom, the last section should always be active.
        // We use a delta of 50px so that we activate the last section some px before
        // actually reaching the page end with the scroll
        sectionThatShouldBeActiveId = scrollSpySectionIds[scrollSpySectionIds.length - 1];
      }

      // Only update active tab if the user actually scrolled to another section
      if (currentlyActiveSectionId !== sectionThatShouldBeActiveId) {
        setSectionActive(sectionThatShouldBeActiveId);
      }
    };

    window.addEventListener('scroll', updateNavigationTabsOnScroll, { capture: true });
    window.addEventListener('resize', updateNavigationTabsOnScroll, { capture: true });

    // Handle scrolling to section when clicking on navigation link
    document.querySelectorAll('.js-tabs-with-anchor-anchor').forEach((anchor) => {
      anchor.addEventListener('click', (event) => {
        const href = anchor.getAttribute('href');

        // Don't let the browser scroll do the anchor default scroll
        event.preventDefault();

        const section = document.querySelector(href);
        // We always scroll to top for the first section
        const isFirstSection = firstSectionId === section.id;
        // The 3px is needed to be exactly in the section we want to scroll to and
        // to not be on the frontier between two sections
        const offsetPosition = isFirstSection ? 0 : section.offsetTop - getFullHeaderOffset() + 3;

        scrollContainer.scrollTo({
          top: offsetPosition,
          left: 0,
          behavior: 'smooth',
        });
      });
    });

    // So that the first tab is active initially
    setSectionActive(currentlyActiveSectionId);
  }
});
