window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-modal').forEach((modal) => {
    const submit = modal.querySelector('.js-proposals__cta input[type=submit]');
    const modalClasses = '.js-proposals-accept-modal__appointment, .js-proposals-refuse-modal__option';
    const modalOptions = modal.querySelectorAll(modalClasses);

    modalOptions.forEach((anchor) => {
      anchor.addEventListener('click', () => {
        if (submit.getAttribute('disabled')) {
          submit.removeAttribute('disabled');
        }
      });
    });
  });
});
