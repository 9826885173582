import DOMPurify from 'dompurify';

window.addEventListener('DOMContentLoaded', () => {
  const readMoreButton = document.querySelector('.js-reviews-list-read-more');
  const reviewsList = document.querySelector('.js-reviews-list');
  let currentPage = 1;

  if (readMoreButton && reviewsList) {
    const maxReviews = 50;
    let currentDisplayedReviews = 5;
    const numberOfReviewsToLoad = 5;
    let loading = false;
    const readMoreButtonText = readMoreButton.textContent;

    // Modify stars depending on pro rate
    const setStarsForProRate = (templateHTML, review) => {
      const maxStars = 5;
      const ratingContainer = templateHTML.querySelector('.rating');
      /*
      * Here we always have 4 stars full and one empty star at the end because in the ruby file in
      * "read_more_data" method, the value of "pro_rate" is always equal to 4
      */
      const emptyStar = ratingContainer.querySelector('.rating__icon:last-child').outerHTML;
      const fullStar = ratingContainer.querySelector('.rating__icon:first-child').outerHTML;

      for (let i = 0; i < maxStars; i += 1) {
        const starIndex = i + 1;
        const starToAdd = ratingContainer.querySelector(`.rating__icon:nth-child(${starIndex})`);
        starToAdd.innerHTML = starIndex <= review.pro_rate ? fullStar : emptyStar;
      }
    };

    const fetchReviews = async (url) => {
      try {
        let lastReviewDate;

        const response = await fetch(url);
        const reviews = await response.json();

        reviews.forEach((review) => {
          // Replace template with review's values
          const template = readMoreButton.getAttribute('data-template')
            .replace('%review', DOMPurify.sanitize(review.pro_comment))
            .replace('%author', DOMPurify.sanitize(review.first_name))
            .replace('%zip_code', DOMPurify.sanitize(review.zip_code))
            .replace('%city', DOMPurify.sanitize(review.city))
            .replace('%session', DOMPurify.sanitize(review.prestations_humanized))
            .replace('4/5', DOMPurify.sanitize(`${review.pro_rate}/5`));

          // Convert string to HTML
          const templateHTML = document.createElement('div');
          templateHTML.innerHTML = template;
          templateHTML.querySelector('.review__rating-date').textContent = review.date_humanized;

          setStarsForProRate(templateHTML, review);

          // Append review to reviews list
          reviewsList.append(templateHTML.firstElementChild);

          // Update last review date for pagination of reviews
          lastReviewDate = review.created_at;
        });

        // Update last review date for pagination of reviews
        readMoreButton.setAttribute('data-last-review-date', lastReviewDate);

        currentDisplayedReviews += numberOfReviewsToLoad;
        readMoreButton.textContent = readMoreButtonText;
        loading = false;

        if (currentDisplayedReviews >= maxReviews || !reviews.length) readMoreButton.remove();
      } catch (error) {
        // TODO: remove this alert
        // eslint-disable-next-line no-alert
        alert('Une erreur est survenue.\nMerci de réessayer.\nSi le problème persiste, merci de nous contacter.');
      }
    };

    readMoreButton.addEventListener('click', () => {
      const target = readMoreButton.getAttribute('data-target');
      const paginationBy = readMoreButton.getAttribute('data-pagination-by');
      const locationType = readMoreButton.getAttribute('data-location-type');
      const locationSlug = readMoreButton.getAttribute('data-location-slug');

      const url = new URL(window.location.origin + target);
      if (paginationBy === 'pages') {
        currentPage += 1;
        url.searchParams.append('page', currentPage);
      } else {
        url.searchParams.append('last_review_date', readMoreButton.getAttribute('data-last-review-date'));
        url.searchParams.append('location_type', locationType);
        url.searchParams.append('location_slug', locationSlug);
      }

      if (currentDisplayedReviews < maxReviews && !loading) {
        readMoreButton.textContent = `${readMoreButtonText} ...`;
        loading = true;
        fetchReviews(url, readMoreButton);
      }
    });
  }
});
