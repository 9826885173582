window.dataLayer = window.dataLayer || [];

document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.getElementsByClassName('button');
  [...buttons].forEach((button) => {
    if (button.dataset.track) {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        const eventData = JSON.parse(button.dataset.track);
        window.dataLayer.push({
          event: 'LogEvent',
          eventType: eventData.event_type,
          eventProperties: eventData.event_properties,
        });
        window.location.href = button.href;
      });
    }
  });
});
