window.addEventListener('DOMContentLoaded', () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const { elementToChange, className } = entry.target.dataset;
      document.querySelectorAll(elementToChange).forEach((element) => {
        element.classList.toggle(className, entry.isIntersecting); // eslint-disable-line no-param-reassign
      });
    });
  });
  document.querySelectorAll('.js-change-if-visible').forEach((element) => {
    observer.observe(element);
  });
});
