window.addEventListener('DOMContentLoaded', () => {
  const modals = document.querySelectorAll('.js-modal');
  const togglers = document.querySelectorAll('.js-modal-toggle');

  if (modals.length && togglers.length) {
    const showModal = (modal) => {
      document.body.classList.add('disable-scroll');
      modal.classList.remove('modal--hide');
    };

    const closeModal = (modal) => {
      document.body.classList.remove('disable-scroll');
      modal.classList.add('modal--hide');
    };

    const bindCloseModal = (element, modal) => {
      if (element) {
        element.addEventListener('click', (event) => {
          event.preventDefault();
          closeModal(modal);
        });
      }
    };

    const bindEscapeKeyOnHide = (modal) => {
      window.addEventListener('keyup', (e) => {
        if (e.key === 'Escape' || e.key === 'Esc' || e.code === 27 || e.code === 53) {
          e.preventDefault();
          closeModal(modal);
        }

        return false;
      }, true);
    };

    [...togglers].forEach((toggler) => {
      const modalId = toggler.getAttribute('data-target');
      const modal = document.getElementById(modalId);

      toggler.addEventListener('click', (event) => {
        event.preventDefault();
        if (modal) showModal(modal);
      });
    });

    [...modals].forEach((modal) => {
      bindEscapeKeyOnHide(modal);
      bindCloseModal(modal.querySelector('.js-modal-close-icon'), modal);
      bindCloseModal(modal.querySelector('.js-modal-close-button'), modal);
      bindCloseModal(modal.querySelector('.js-modal-backdrop'), modal);
    });
  }
});
