window.addEventListener('DOMContentLoaded', () => {
  const dropdowns = document.querySelectorAll('.js-dropdown');

  if (dropdowns.length) {
    dropdowns.forEach((dropdown) => {
      const title = dropdown.querySelector('.js-dropdown-title');

      title.addEventListener('click', () => {
        dropdown.classList.toggle('dropdown--open');
      });
    });
  }
});
