window.addEventListener('DOMContentLoaded', () => {
  const buttonLeft = document.querySelector('.js-reviews-carousel-scroll-left');
  const buttonRight = document.querySelector('.js-reviews-carousel-scroll-right');
  const reviewsCards = document.querySelector('.js-reviews-carousel');

  if (buttonLeft && buttonRight && reviewsCards) {
    reviewsCards.addEventListener('scroll', () => {
      buttonLeft.disabled = reviewsCards.scrollLeft === 0;
      buttonRight.disabled = reviewsCards.scrollLeft + reviewsCards.clientWidth
        >= reviewsCards.scrollWidth;
    });

    buttonLeft.addEventListener('click', () => {
      reviewsCards.scrollTo({ left: 0, behavior: 'smooth' });
    });

    buttonRight.addEventListener('click', () => {
      reviewsCards.scrollTo({ left: reviewsCards.scrollWidth, behavior: 'smooth' });
    });
  }
});
