class AutocompleteAddress {
  constructor(autocompleteAddressElement) {
    this.autocompleteAddressElement = autocompleteAddressElement;
    this.addressField = autocompleteAddressElement.querySelector('.js-form-autocomplete-address-field');
    this.addressLabel = autocompleteAddressElement.querySelector('.js-form-autocomplete-address-field + label');
    this.addressErrorMessage = autocompleteAddressElement.querySelector('.js-form-autocomplete-address-field + label + .form-input-message--error'); // eslint-disable-line max-len
    this.woosmapService = new woosmap.localities.AutocompleteService( // eslint-disable-line no-undef
      this.addressField.dataset.woosmapKey,
    );
    this.geoDetailsElement = autocompleteAddressElement.querySelector('.js-form-autocomplete-address-geo-details');
    this.suggestionsElement = autocompleteAddressElement.querySelector('.js-form-autocomplete-address-suggestions');
    this.errorElement = autocompleteAddressElement.querySelector('.js-form-autocomplete-address-message');

    this.initializeAutocomplete();
  }

  displayError(errorMessage = this.addressField.dataset.geocodingError) {
    if (!this.errorElement) {
      return;
    }

    if (errorMessage) {
      this.errorElement.classList.add('form-autocomplete-address-message--error');
      this.errorElement.innerHTML = errorMessage;
    }
  }

  static getAddressValue(addressComponents, addressTypes, attrName = 'long_name') {
    const addressFound = addressComponents.find(
      (address) => address.types.some((addressType) => addressTypes.includes(addressType)),
    );

    return addressFound && addressFound[attrName];
  }

  callbackSuccess(address) {
    const geoDetails = {
      lat: address.geometry.location.lat,
      lng: address.geometry.location.lng,
      street_number: AutocompleteAddress.getAddressValue(address.address_components, ['street_number', 'premise']),
      street_name: AutocompleteAddress.getAddressValue(address.address_components, ['route', 'sublocality']),
      zip_code: AutocompleteAddress.getAddressValue(address.address_components, ['postal_code']),
      city: AutocompleteAddress.getAddressValue(address.address_components, ['locality', 'postal_town']),
      country_code: AutocompleteAddress.getAddressValue(address.address_components, ['country'], 'short_name'),
    };

    this.addressField.value = address.formatted_address;

    Array.from(this.geoDetailsElement.children).forEach((hiddenInput) => {
      const attrName = hiddenInput.dataset.attributeName;
      hiddenInput.value = geoDetails[attrName]; // eslint-disable-line no-param-reassign
    });
  }

  callbackError(publicId) {
    return (errorCode, errorText) => {
      this.displayError(
        `Woosmap Address "${publicId}" (country: "${this.addressField.dataset.countryCode}") wasn't found. \
        Input Address: "${this.addressField.value}". Error code: "${errorCode}". Error message: "${errorText}"`,
      );
    };
  }

  fillInputField(suggestionElement) {
    const publicId = suggestionElement.dataset.woosmapPublicId;

    this.woosmapService.getDetails(
      publicId,
      this.callbackSuccess.bind(this),
      this.callbackError(publicId).bind(this),
    );
  }

  resetSuggestions() {
    this.suggestionsElement.innerHTML = '';
  }

  setSuggestionOnClick() {
    this.suggestionsElement
      .querySelectorAll('.js-form-autocomplete-address-suggestion')
      .forEach((suggestionElement) => {
        suggestionElement.addEventListener('click', () => {
          this.fillInputField(suggestionElement);
          this.resetSuggestions();
        });
      });
  }

  displaySuggestions(predictions) {
    let html = '';

    if (predictions) {
      predictions.forEach((prediction) => {
        html += `
          <div class="form-autocomplete-address__suggestion js-form-autocomplete-address-suggestion"
            data-woosmap-public-id="${prediction.public_id}">
            ${prediction.description}
          </div>`;
      });
    }

    this.suggestionsElement.innerHTML = html;
    this.setSuggestionOnClick();
  }

  findWoosmapAddressPredictions() {
    const autocompletionRequest = {
      input: this.addressField.value,
      types: 'address',
      components: { country: this.addressField.dataset.countryCode },
    };

    this.woosmapService.autocomplete(
      autocompletionRequest,
      (autocompleteResponse) => {
        this.displaySuggestions(autocompleteResponse.localities);
      },
      (errorCode, errorText) => {
        this.resetSuggestions();
        this.displayError(errorText);
      },
    );
  }

  resetErrors() {
    this.addressField.classList.remove('form-autocomplete-address__field--error');
    this.addressLabel.classList.remove('form-autocomplete-address__label--error');
    if (this.addressErrorMessage) this.addressErrorMessage.remove();
  }

  handleInputFieldChange() {
    if (this.addressField.value.length <= 3) {
      this.resetSuggestions();
    } else {
      this.resetErrors();
      this.findWoosmapAddressPredictions();
    }
  }

  initializeAutocomplete() {
    this.addressField.addEventListener('input', this.handleInputFieldChange.bind(this));
  }
}

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-form-autocomplete-address').forEach((autocompleteAddressElement) => {
    new AutocompleteAddress(autocompleteAddressElement); // eslint-disable-line no-new
  });
});
