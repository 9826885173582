document.addEventListener('DOMContentLoaded', () => {
  const inputPassword = document.querySelector('.form-validated-password')?.children?.[0];
  if (inputPassword == null) return;

  const rulesContainer = document.querySelector('.rules-container');
  const isError = rulesContainer.attributes.getNamedItem('data-error').value === 'true';

  const rules = {
    majuscule: {
      regex: /[A-Z]/,
      target: document.querySelector('.rule#majuscule'),
    },
    minuscule: {
      regex: /[a-z]/,
      target: document.querySelector('.rule#minuscule'),
    },
    number: {
      regex: /[0-9]/,
      target: document.querySelector('.rule#number'),
    },
    symbol: {
      regex: /[!@#$%^&*()_+\-=[\]{}|'"/\\.,`<>:;?~]/,
      target: document.querySelector('.rule#symbol'),
    },
    length: {
      test: (value) => value.length >= 8,
      target: document.querySelector('.rule#length'),
    },
  };

  function isRuleValid(rule, value) {
    return rule.regex ? value.match(rule.regex) : rule.test(value);
  }

  function updateRuleAppearance(target, { iconHidden = false, headerInvalid = false, valid = false }) {
    const icon = target.children[0].children[0];
    icon.classList.toggle('form-validated-password--rule__icon--hidden', iconHidden);

    const header = target.children[1];
    header.classList.toggle('form-validated-password--rule-invalid', headerInvalid);
    header.hidden = !iconHidden;

    const title = target.children[2];
    title.classList.toggle('form-validated-password--rule-valid', valid);
    title.classList.toggle('form-validated-password--rule-invalid', headerInvalid);
  }

  function makeRuleValid(ruleTarget) {
    updateRuleAppearance(ruleTarget, { valid: true });
  }

  function makeRuleNeutral(ruleTarget) {
    updateRuleAppearance(ruleTarget, { iconHidden: true });
  }

  function makeRuleInvalid(ruleTarget) {
    updateRuleAppearance(ruleTarget, { iconHidden: true, headerInvalid: true });
  }

  function refreshTargets(value, falseCallback) {
    Object.values(rules).forEach(({ target, ...rule }) => {
      const callback = isRuleValid(rule, value) ? makeRuleValid : falseCallback;
      callback(target);
    });
  }

  if (isError) {
    refreshTargets(inputPassword.value, makeRuleInvalid);
  } else {
    rulesContainer.hidden = true;
  }

  inputPassword.addEventListener('focus', (event) => {
    refreshTargets(event.target.value, makeRuleNeutral);
    rulesContainer.hidden = false;
  });

  inputPassword.addEventListener('input', (event) => {
    refreshTargets(event.target.value, makeRuleNeutral);
    rulesContainer.hidden = false;
  });

  inputPassword.addEventListener('blur', (event) => {
    refreshTargets(event.target.value, makeRuleInvalid);
    const allFieldsValid = Object.values(rules).every(({ target }) => (
      target.getElementsByClassName('form-validated-password--rule-valid').length === 1
    ));
    if (allFieldsValid) {
      rulesContainer.hidden = true;
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const inputPassword = document.querySelector('.form-validated-password')?.children?.[0];
  if (inputPassword == null) return;

  const iconOpen = document.querySelector('.form-validated-password--eye-icon-open');
  const iconClose = document.querySelector('.form-validated-password--eye-icon-close');

  function replaceHiddenClass(targetToShow, targetToHide) {
    targetToShow.classList.remove('form-validated-password--eye-icon__hidden');
    targetToHide.classList.add('form-validated-password--eye-icon__hidden');
  }

  replaceHiddenClass(iconOpen, iconClose); // Initial state

  function togglePassword() {
    const isPassword = inputPassword.type === 'password';
    const targetToShow = isPassword ? iconClose : iconOpen;
    const targetToHide = isPassword ? iconOpen : iconClose;

    replaceHiddenClass(targetToShow, targetToHide);
    inputPassword.type = isPassword ? 'text' : 'password';
  }

  iconOpen.addEventListener('click', togglePassword);
  iconClose.addEventListener('click', togglePassword);
});
