// Styled using https://mapstyle.withgoogle.com/
const mapStyleGrey = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

let mapWaitCount = 0;
const mapWaitMax = 10;

function loadMapWhenWoosmapMapLibReady() {
  mapWaitCount += 1;
  if (typeof woosmap !== 'undefined') {
    const mapDivs = document.querySelectorAll('.js-map');
    if (mapDivs.length) {
      mapDivs.forEach((mapDiv) => {
        const latlng = {
          lat: Number(mapDiv.dataset.lat),
          lng: Number(mapDiv.dataset.lng),
        };
        const map = new window.woosmap.map.Map(mapDiv, {
          center: latlng,
          zoom: Number(mapDiv.dataset.zoom) || 12,
          styles: mapStyleGrey,
        });
        if (mapDiv.dataset.circleRadius) {
          const circleRadius = Number(mapDiv.dataset.circleRadius) * 1000;
          // eslint-disable-next-line no-new
          new window.woosmap.map.Circle({
            strokeColor: getComputedStyle(document.documentElement).getPropertyValue('--blue'),
            strokeOpacity: 0.4,
            strokeWeight: 2,
            fillColor: getComputedStyle(document.documentElement).getPropertyValue('--blue'),
            fillOpacity: 0.2,
            map,
            center: latlng,
            radius: circleRadius,
          });
        }
      });
    }
  } else if (mapWaitCount < mapWaitMax) {
    console.warn(`Woosmap Map API loading : Waiting attempt #${mapWaitCount}`); // eslint-disable-line no-console
    setTimeout(() => { loadMapWhenWoosmapMapLibReady(); }, 1000);
  } else if (mapWaitCount >= mapWaitMax) {
    console.error('Failed to load Woosmap Map API!'); // eslint-disable-line no-console
  }
}

window.addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('.js-map').length) loadMapWhenWoosmapMapLibReady();
});
