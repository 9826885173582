window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.youtube').forEach((youtube) => {
    youtube.addEventListener('click', (event) => {
      const iframe = document.createElement('iframe');
      const target = event.currentTarget;

      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('src', `https://www.youtube.com/embed/${target.dataset.embed}?rel=0&showinfo=0&autoplay=1`);
      target.innerHTML = '';
      target.appendChild(iframe);
    });
  });
});
