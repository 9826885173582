window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-trigger-click').forEach((element) => {
    const target = document.querySelector(element.dataset.elementToClick);
    element.addEventListener('click', (event) => {
      event.preventDefault();
      if (target) {
        target.click();
      }
    });
  });
});
