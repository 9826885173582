window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.welcome-notice').forEach((notice) => {
    notice.querySelector('.welcome-notice__close-icon').addEventListener('click', (e) => {
      e.preventDefault();
      notice.classList.add('welcome-notice--hide');
      if (notice.dataset.id) {
        document.cookie = `${notice.dataset.id}=dismissed`;
      }
    });
  });
});
