window.addEventListener('DOMContentLoaded', () => {
  const breadcrumbDots = document.querySelector('.js-breadcrumb-dots');

  if (breadcrumbDots) {
    breadcrumbDots.addEventListener('click', () => {
      document.querySelectorAll('.js-breadcrumb-hidden').forEach((breadcrumbItem) => {
        breadcrumbItem.classList.remove('breadcrumb__item--hide-on-mobile');
      });

      document.querySelectorAll('.js-breadcrumb-separator-hidden').forEach((breadcrumbSeparator) => {
        breadcrumbSeparator.classList.remove('breadcrumb__separator--hide-on-mobile');
      });

      breadcrumbDots.remove();
      document.querySelector('.js-breadcrumb-separator-shown').remove();
    });
  }
});
