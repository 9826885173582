import Inputmask from 'inputmask';
import dayjs from 'dayjs';
import dateFormat from '../../../frontend/javascripts/utils/date_formats';

window.addEventListener('DOMContentLoaded', () => {
  const dateFields = document.getElementsByClassName('js-form-input-field-date');
  dateFields.forEach((dateField) => {
    new Inputmask('99/99/9999', {
      placeholder: ' ',
      showMaskOnHover: false,
      showMaskOnFocus: false,
      onBeforeMask: (value) => (
        dayjs(value).format(dateFormat[document.documentElement.lang] || dateFormat.en)
      ),
    }).mask(dateField);
  });
});
